import React, { useState, useEffect } from "react";
import "./productList.css";

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const gistUrl = "https://gist.github.com/Parzival-101/eb8602a006b87948f0df6492d5aa584bhttps://gist.githubusercontent.com/Parzival-101/eb8602a006b87948f0df6492d5aa584b/raw/d07f1832b6ef0068191da7c8a822bf4e52415458/products.jsonhttps://gist.githubusercontent.com/Parzival-101/eb8602a006b87948f0df6492d5aa584b/raw/d07f1832b6ef0068191da7c8a822bf4e52415458/products.json"; // Replace with your Gist URL
    const localJsonFile = "/products.json"; // Local file in the public directory for fallback

    useEffect(() => {
        // Attempt to fetch data from GitHub Gist first
        fetchGistData();
    }, []);

    const fetchGistData = async () => {
        try {
            const response = await fetch(gistUrl);
            if (!response.ok) throw new Error("Failed to fetch from Gist");

            const data = await response.json();
            setProducts(data);
            localStorage.setItem('projectsData', JSON.stringify(data)); // Cache the data for future use
        } catch (error) {
            console.error("Error fetching from Gist, attempting to load local JSON:", error);
            fetchLocalData(); // If fetching from Gist fails, fallback to local JSON
        }
    };

    const fetchLocalData = async () => {
        try {
            const response = await fetch(localJsonFile);
            if (!response.ok) throw new Error("Failed to fetch local JSON");

            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error("Error fetching local JSON data:", error);
            // Optional: fallback to hardcoded or static data if both fail
            setProducts([]);
        }
    };

    const visibleProducts = showAll ? products : products.slice(0, 8); // Show only 8 initially

    return (
        <section className="product-list">
            <div id="projects" className="p">
                <div>
                    <center><h1 className="c-title">Projects and Apps</h1></center>
                </div>
                <div className="products-container">
                    {visibleProducts.map((product) => (
                        <div key={product.id} className="product">
                            <h3>{product.title}</h3>
                            <br />
                            {product.link ? (
                                <a href={product.link} target="_blank" rel="noopener noreferrer">
                                    <img src={product.img} alt={product.title} className="product-img" />
                                </a>
                            ) : (
                                <img src={product.img} alt={product.title} className="product-img" />
                            )}
                            <p>{product.description}</p>
                        </div>
                    ))}
                </div>
                {products.length > 10 && (
                    <button onClick={() => setShowAll(!showAll)} className="show-all-btn">
                        {showAll ? "Show Less" : "Show All"}
                    </button>
                )}
            </div>
        </section>
    );
};

export default ProductList;
